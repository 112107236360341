import React from 'react';
import TextField from '@material-ui/core/TextField';
import './DatePicker.css';

export default class DatePicker extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <form className="date-container" noValidate>
        <TextField
          error={this.props.error}
          id={this.props.id}
          label={this.props.label}
          type="date"
          required
          defaultValue={this.props.defaultValue}
          className="date-textfield"
          onChange={(e) => this.props.onChange(e)}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </form>
    );
  }
}
