import React from 'react';
import './StackedBarChart.css';
import { formatDate } from './../../../utils/utils';

const Highcharts = global.Highcharts;

export default class StackedBarChart extends React.Component {

    constructor(props) {
        super(props);
        // this.props.data
        this.state = {
            categories: this.props.data?.map(val => formatDate(val["ImageTimeStamp"]))
                .filter((value, index, self) => self.indexOf(value) === index)
        }


    }

    componentDidMount() {

        let title = this.props.title;
        let categories = this.state.categories;
        // console.log('cat: ', categories);
        const createSeries = (acc, val, type) => {
            if (acc.hasOwnProperty(val[type])) {
                acc[val[type]] += 1
            } else {
                acc[val[type]] = 1
            }
            return acc;
        }
        const dateSeries = this.state.categories.map(cat => this.props.data.filter(val => cat === formatDate(val["ImageTimeStamp"])));
        const reducedSeries = dateSeries.map((arr = []) => {
            return arr.reduce((acc = {}, val, ind) => {
                acc = createSeries(acc, val, 'CompositeWellGrade1_DisplayValue');
                acc = createSeries(acc, val, 'CompositeWellGrade2_DisplayValue');
                acc = createSeries(acc, val, 'CompositeWellGrade3_DisplayValue');
                acc = createSeries(acc, val, 'CompositeWellGrade4_DisplayValue');
                acc = createSeries(acc, val, 'CompositeWellGrade5_DisplayValue');
                acc = createSeries(acc, val, 'CompositeWellGrade6_DisplayValue');
                return acc
            }, {});
        });
        //to get all keys/options 
        const options = Object.assign({}, ...reducedSeries);
        const optionsKeys = Object.keys(options);
        const finalSeries = reducedSeries.reduce((acc = {}, val, ind) => {
            // let keys = Object.keys(val);
            optionsKeys.forEach(data => {
                if (acc.hasOwnProperty(data)) {

                    acc[data] = {
                        "name": data,
                        "data": [...acc[data]["data"], val[data] ? val[data] : 0]
                    }
                } else {
                    acc[data] = {
                        "name": data,
                        "data": [val[data] ? val[data] : 0]
                    }
                }
            });
            return acc;
        }, {});

        // console.log('final series', finalSeries);


        Highcharts.chart('stacked-bar-chart', {
            chart: {
                type: 'column'
            },
            title: {
                text: title ? title : 'Image Count By Day'
            },
            xAxis: {
                categories: categories
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Composite Well Grade Count'
                },
                stackLabels: {
                    enabled: true,
                    style: {
                        fontWeight: 'bold',
                        color: ( // theme
                            Highcharts.defaultOptions.title.style &&
                            Highcharts.defaultOptions.title.style.color
                        ) || 'gray'
                    }
                }
            },
            legend: {
                align: 'right',
                x: -30,
                verticalAlign: 'top',
                y: 25,
                floating: true,
                backgroundColor:
                    Highcharts.defaultOptions.legend.backgroundColor || 'white',
                borderColor: '#CCC',
                borderWidth: 1,
                shadow: false
            },
            tooltip: {
                headerFormat: '<b>{point.x}</b><br/>',
                pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                    dataLabels: {
                        enabled: true
                    }
                }
            },
            series: Object.values(finalSeries)
        });
    }

    render() {
        return (
            <div className="stacked-bar-graph">
                <figure className="highcharts-figure">
                    <div id="stacked-bar-chart"></div>
                </figure>
            </div>
        );
    }
}


// [{
//     name: 'PERIOD_1',
//     data: [5, 3, 4]
// }, {
//     name: 'PERIOD_2',
//     data: [2, 2, 3]
// }, {
//     name: 'PERIOD_3',
//     data: [3, 0, 4]
// }, {
//     name: 'PERIOD_4',
//     data: [3, 4, 4]
// }, {
//     name: 'PERIOD_5',
//     data: [3, 4, 4]
// }]