import React from 'react';
import './GroupBarChart.css';

const Highcharts = global.Highcharts;

export default class GroupBarChart extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {

        Highcharts.chart('group-bar-chart', {
            chart: {
                type: 'column'
            },
            title: {
                text: 'Overall Images By Hour'
            },
            subtitle: {
                text: 'INSTRUMENT ID : 50001010'
              },
            xAxis: {
                categories: [
                    '10:00',
                    '11:00',
                    '12:00',
                    '13:00',
                    '14:00',
                    '15:00',
                    '16:00',
                    '17:00',
                    '18:00',
                    '19:00',
                    '20:00',
                    '21:00'
                ],
                crosshair: true
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Image Count'
                }
            },
            tooltip: {
                headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                    '<td style="padding:0"><b>{point.y}</b></td></tr>',
                footerFormat: '</table>',
                shared: true,
                useHTML: true
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            series: [{
                name: 'GradeTypeOnePositive',
                data: [49, 71, 106, 129, 144, 176, 135, 145, 214, 194, 95, 54]

            }, {
                name: 'GradeTypeBubble',
                data: [83, 78, 98, 93, 100, 84, 105, 104, 91, 83, 106, 93]

            }, {
                name: 'GradeTypeFourPositive',
                data: [48, 38, 39, 41, 47, 48, 59, 59, 52, 65, 59, 51]

            }, {
                name: 'GradeTypeIndeterminate',
                data: [42, 33, 34, 39, 52, 75, 57, 60, 47, 39, 46, 51]

            },
            {
                name: 'GradeTypeThreePositive',
                data: [49, 71, 106, 129, 144, 176, 135, 148, 216, 194, 95, 54]

            }, {
                name: 'GradeTypeTooFewCells',
                data: [83, 78, 98, 93, 106, 84, 105, 104, 91, 83, 106, 92]

            }, {
                name: 'GradeTypeTwoPositive',
                data: [48, 38, 39, 41, 47, 48, 59, 59, 52, 65, 59, 51]

            }, {
                name: 'GradeTypeWrongLiquidLevel',
                data: [42, 33, 34, 39, 52, 75, 57, 60, 47, 39, 46, 51]

            }]
        });
    }

    render() {
        return (
            <div className="group-bar-graph">
                <figure className="highcharts-figure">
                    <div id="group-bar-chart"></div>
                </figure>
            </div>
        );
    }
}