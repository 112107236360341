import React from 'react';
// import Button from '@material-ui/core/Button';
// import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
// import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
// import DialogTitle from '@material-ui/core/DialogTitle';
import './Dialog.css';
import Modal from '@material-ui/core/Modal';
import { callApi } from '../../../utils/auth-utilities'

export default class ImageryDialog extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    const tableData = {
      "Col1": ["1+", "2+", "3+", "2+"], "Col2": ["?", "1+", "0", "4+"], "Col3": ["?", "4+", "3+", "2+"], "Col4": ["?", "0", "0", "0"]
      , "Col5": ["?", "0", "0", "0"], "Col6": ["?", "0", "?", "?"]
    };
    return (
      <div className="imagery-dialog">
        <Modal
          open={this.props.open}
          onClose={this.props.close}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div className="app-image">
            <div className="selected-images">
              <div>
                <img alt="results_color" width="500px" height="500px" src={'data:image/png;base64,' + (this.props.open ? this.props.imgSrc : null)}></img>
              </div>
            </div>
          </div>


        </Modal>
      </div>
    );
  }
}