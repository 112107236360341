import { Button, TextField } from '@material-ui/core';
import React from 'react';
import DatePicker from '../../core/components/DatePicker/DatePicker';
import './SearchFilter.css';

export default class SearchFilter extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            jNumber: null,
            startDate: null,
            endDate: null
        }
    }

    jnumberChange = (e) => {
        this.setState({ jNumber: e.target.value });
    }

    startDateChange = (e) => {
        // console.log(e.target.value);
        this.setState({ startDate: e.target.value });
    }

    endDateChange = (e) => {
        // console.log(e.target.value);
        this.setState({ endDate: e.target.value });
    }

    search = () => {
        const criteria = {
            jNumber: this.state.jNumber,
            startDate: this.state.startDate,
            endDate: this.state.endDate
        };
        this.props.search(criteria);
    }

    render() {
        return (
            <div className="filter-main">
                <div className="filter-jnumber">
                    <TextField id="standard-basic" label="JNumber" onChange={(e) => this.jnumberChange(e)} />
                </div>

                <div className="filter-daterange">
                    <div>
                        <DatePicker id="start-date" label="Start Date" defaultValue="" onChange={(e) => this.startDateChange(e)} ></DatePicker>
                    </div>
                    <div>
                        <DatePicker id="end-date" label="End Date" defaultValue="" onChange={(e) => this.endDateChange(e)} ></DatePicker>
                    </div>
                </div>
                <div>
                    <Button variant="outlined" onClick={this.search}>Search</Button>
                </div>
            </div>
        );
    }
}