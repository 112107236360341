import React from 'react';
import GroupBarChart from '../../core/components/GroupBarChart/GroupBarChart';
import SearchFilter from '../SearchFilter/SearchFilter';
import './UserGuide.css';


export default class UserGuide extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <React.Fragment>
                <div className="app-filter">
                    <SearchFilter search={(criteria) => this.loadImageData(criteria)}></SearchFilter>
                </div>
                <div className="app-body">
                    <GroupBarChart></GroupBarChart>
                </div>
            </React.Fragment>
        );
    }
}