import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';
import Auth from 'aws-amplify';
import * as Constants from './utils/constants';


// Add a request interceptor
axios.interceptors.request.use(request => {
  // Do something before request is sent
  // request.headers["Authorization"] = "Bearer " + Cookies.get(Constants.AUTH_TOKEN_COOKIE_NAME);
  // let accessToken;
  // let jwt;
  // let cognitoSession;
  // const setTokens = async () => {
  //   await Auth.currentSession().then(res => {
  //     accessToken = res.getAccessToken();
  //     jwt = res.getIdToken().getJwtToken();

  //     cognitoSession = res;
  //     // isValid = res.isValid.bind(res);
  //     console.log(accessToken, jwt, cognitoSession.isValid());
  //   });
  // }
  // setTokens();
  // request.headers["Authorization"] = "Bearer " + jwt
  return request;
}, error => {
  // Do something with request error
  console.log('some error');
  return Promise.reject(error);
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
