import { callApi } from '../../utils/auth-utilities'

function getvalueCodes() {
    return callApi('gradedresultcalculationvalues', {}, { 'Content-Type': 'application/json' }, 'GET');
    // return fetch('/WS_Imagery.svc/GradedResultCalculationValues', { method: 'get' });
}

function searchImagery(params) {
    return callApi('imageattributes', params, { 'Content-Type': 'application/json' }, 'POST');
    // return fetch('/WS_Imagery.svc/ImageAttributes', { method: 'post', body: JSON.stringify(params), headers: { 'Content-Type': 'application/json' } });
}


export { getvalueCodes, searchImagery }