import React from 'react';
import ImageryNewSearchFilter from '../ImageryNewSearchFilter/ImageryNewSearchFilter';
import './ImageryNew.css';


export default class ImageryNew extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tableData: [],
            rawData: []
        }
    }


    render() {

        return (
            <React.Fragment>
                <div className="app-body">
                    <ImageryNewSearchFilter openDialog={this.props.openDialog} setImgSrc={this.props.setImgSrc} startLoading={this.props.startLoading} stopLoading={this.props.stopLoading} />
                </div>
            </React.Fragment>
        );
    }
}