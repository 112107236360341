import * as Constants from "./constants";
import Cookies from "js-cookie";
import axios from 'axios';
import { Auth } from 'aws-amplify'

const JWT = require("jsonwebtoken");

/**
 * Will return True if the auth_token cookie is present and not expired.
 */

// async function getAccessToken() {
//   let accessToken;
//   await Auth.currentSession().then(res => {
//     accessToken = res.getAccessToken().getJwtToken();
//   });

//   return accessToken;
// }
export async function hasValidToken() {
  // const token = Cookies.get(Constants.AUTH_TOKEN_COOKIE_NAME);
  let accessToken;
  let jwt;
  let isValid;
  await Auth.currentSession().then(res => {
    accessToken = res.getAccessToken();
    jwt = accessToken.getJwtToken();
    isValid = res.isValid;
    // console.log(accessToken, jwt, isValid());
  })
  // if (accessToken !== undefined) {
  //   const decodedToken = JWT.decode(jwt);
  //   const dateNow = parseInt(Date.now().toString().substr(0, 10));
  //   if (decodedToken.exp > dateNow) return true;
  //   console.log("Token has expired");
  //   return false;
  //   // Cookies.remove(Constants.AUTH_TOKEN_COOKIE_NAME);
  // }
  // return false;
  return isValid();
}

export function isEmployee(idToken) {
  if (idToken !== undefined) {
    const decodedToken = JWT.decode(idToken);
    if (decodedToken["custom:UserType"] === "E") return true;
  }
  return false;
}

export function isUnauthorized() {
  const cookie = Cookies.get(Constants.INVALID_TOKEN_COOKIE_NAME);
  return cookie !== undefined;
}

/**
 * Refreshes the app page.
 */
// export function refreshPage() {
//   window.location.href = Constants.APP_URL;
// }

/**
 * Redirects the page to the Ortho login screen.
 */
export function redirectToLogin() {
  // console.log('redirecting to login')
  // axios.get(Constants.LOGIN_URL);
  window.location.href = Constants.LOGIN_URL;
  // window.location.reload(Constants.LOGIN_URL)
}

// export function redirectToLogout() {
//   window.location.href = Constants.LOGOUT_URL;
// }

export function redirectToInvalidateToken() {
  Cookies.remove(Constants.AUTH_TOKEN_COOKIE_NAME);
  Cookies.set(Constants.INVALID_TOKEN_COOKIE_NAME, false, {
    expires: 60 / 86400, // 86400 = 1 day in seconds
  });
  // refreshPage();
}

export function redirectToHome() {
  window.location.href = Constants.HOME_URL;
}

// export function redirectToCognitoLogout() {
//   // Cookies.remove(Constants.AUTH_TOKEN_COOKIE_NAME);
//   // axios.request(Constants.COGNITO_LOGOUT_URL);
//   window.location.href = Constants.COGNITO_LOGOUT_URL;
// }

/**
 * Makes an authenticated call to the api endpoint specified.
 *
 * Returns: An XMLHttpRequest on success. Otherwise undefined (will redirect).
 */
export async function callApi(endpoint, data, headers = null, method = "POST") {
  let accessToken;
  let jwt;
  let cognitoSession;
  await Auth.currentSession().then(res => {
    accessToken = res.getAccessToken();
    jwt = res.getIdToken().getJwtToken();

    cognitoSession = res;
    // isValid = res.isValid.bind(res);
    // console.log(accessToken, jwt, cognitoSession.isValid());
  });
  // console.log("jwt :-------", jwt)
  if (cognitoSession.isValid()) {
    const url = Constants.CONFIG_API_URL + endpoint;
    headers.authorization = "Bearer " + jwt;
    // const http = new XMLHttpRequest();
    // http.open(method, url);
    // http.setRequestHeader(
    //   "Authorization",
    //   "Bearer " + Cookies.get(Constants.AUTH_TOKEN_COOKIE_NAME)
    // );
    // for (const key of Object.keys(headers)) {
    //   http.setRequestHeader(key, headers[key]);
    // }
    // http.send(JSON.stringify(data));
    // return http;
    return axios.request({ method, data, url, headers });
  } else {
    Auth.federatedSignIn();
    return "";
  }
}

export default {
  hasValidToken,
  redirectToLogin,
  callApi,
  isEmployee,
  isUnauthorized,
  redirectToInvalidateToken,
  redirectToHome,
};
